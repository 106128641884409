<!--  -->
<template>
    <div class='subject-list g0'>
        <component-bcg url="subject/bcg.png"></component-bcg>
        <div class="g-content">
            <pageBcg></pageBcg>
        </div>
    </div>
</template>

<script>
    import pageBcg from './bcg/g0.vue'

    export default {
        name: '',
        props: {},
        data() {
            return {
                bcgList: [
                    {class: 'b1'}, //树
                    {class: 'b2'}, //草
                    {class: 'b3'}, //花
                    {class: 'b4'}, //植物阴影
                    {class: 'b5'}, //草阴影
                    {class: 'b6',
                        children: [
                            {class: 'b12'},
                            {class: 'b7'},
                            {class: 'b8'},
                            {class: 'b9'},
                            {class: 'b11'},
                            {class: 'b10'},
                        ]
                    }, //左侧山丘
                    {class: 'b13'}, //河流
                    {class: 'b14'}, //河流
                ]
            };
        },
        methods: {

        },
        created() {

        },
        components: {
            pageBcg
        },
        computed: {},
        watch: {},
        mounted() {},
        beforeCreate() {}, //生命周期 - 创建之前
        beforeMount() {}, //生命周期 - 挂载之前
        beforeUpdate() {}, //生命周期 - 更新之前
        updated() {}, //生命周期 - 更新之后
        beforeDestroy() {}, //生命周期 - 销毁之前
        destroyed() {}, //生命周期 - 销毁完成
        activated() {}, //如果页面有keep-alive缓存功能，这个函数会触发
    }
</script>
<style lang='less' >
    .subject-list.g0{
        @headZindex: 1;
        .g-head{
            position: absolute;
            width: 100%;
            top: 0px;
            left: 0px;
            z-index: @headZindex;
            .body{
                width: 654px;
                height: 63px;
                .background-img-max(url('@{assetsUrlV2_0}/subject/c1.png'));
            }
        }
    }
    
</style>